import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Col, Container, Row, Button, Card } from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { clearTarjetaInfo, startGetTarjetaValues } from '../../actions/tarjetas'
import { isTokenStillValid, parseJWT } from '../../helpers/validateToken'
import { encrypt, decryptObject, encryptObject } from '../../helpers/crypto'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'
// import { TimerRateLimit } from './TimeRateLimit'

export const PartnerPortal = ({ userToken }) => {
  const [loading, setLoading] = useState(false)
    const [giftcards, setGiftcards] = useState([])
    const [vouchers, setVouchers] = useState([])

  const { rateLimit } = useSelector((state) => state.auth)

  const store = useStore()

  const captchaRef = useRef()
  const btnRef = useRef()

  const dispatch = useDispatch()
  const encryptedKey = encrypt(process.env.REACT_APP_API_KEY)

  const navigate = useNavigate()

  const getAssignedGiftcards = async() => {
    const url = process.env.REACT_APP_API_URL + '/client/giftcards';
    const token = localStorage.getItem('token') || '';
    const encryptedToken = encrypt(token);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        key: encryptedKey,
        Authorization: `Bearer ${encryptedToken}`,
      },
    };
    const requestData = {
      clientID: localStorage.getItem('clientId')
    }

    const encryptedData = encryptObject(requestData)

    const res = await axios.post(url, { data: encryptedData }, config)

    const result = decryptObject(res.data.data)

    setGiftcards(result)
  }

  const getAssignedVouchers= async () => {
    const url = process.env.REACT_APP_API_URL + '/client/vouchers'
    const token = localStorage.getItem('token') || ''
    const encryptedToken = encrypt(token)

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        key: encryptedKey,
        Authorization: `Bearer ${encryptedToken}`,
      },
    }
    const requestData = {
      clientID: localStorage.getItem('clientId'),
    }

    const encryptedData = encryptObject(requestData)

    const res = await axios.post(url, { data: encryptedData }, config)

    const result = decryptObject(res.data.data)

    setVouchers(result)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getAssignedGiftcards()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    getAssignedVouchers()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(clearTarjetaInfo())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) {
        setLoading(false)
      }
    }, 5000)

    return () => clearTimeout(timer)
  }, [loading])

  const initialForm = {
    code: '',
    publicKey: '',
  }

  const [values, handleInputChange, reset] = useForm(initialForm)

  const { code, publicKey } = values

  let token = localStorage.getItem('token')

  if (!userToken) {
    return <Navigate to="/login" replace />
  }

  return (
    <>
      <Container>
        <br></br>
        <Row>
          <Col xs={0} md={5}>
            <h2 className="bold">Giftcards disponibles</h2>
          </Col>
          <Col xs={12} md={8}></Col>
          <Col xs={0} md={2}></Col>
        </Row>
      </Container>
      <Container className="mt-5 mb-5">
        <Row xs={2} md={4} className="g-4">
          {Array.from(giftcards).map((item, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Body>
                  <Card.Title>Giftcard ${item.amount}</Card.Title>
                  <Card.Text>Disponibles: {item.cards}</Card.Text>
                  <Button
                    className="btn-canjear"
                    onClick={() => navigate(`/giftcard/${item.amount}`)}
                  >
                    Activar
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Container>
        <br></br>
        <Row>
          <Col xs={0} md={5}>
            <h2 className="bold">Vouchers disponibles</h2>
          </Col>
          <Col xs={12} md={8}></Col>
          <Col xs={0} md={2}></Col>
        </Row>
      </Container>

      <Container className="mt-5 mb-5">
        <Row xs={2} md={4} className="g-4">
          {Array.from(vouchers).map((itemVoucher, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Body>
                  <Card.Title>Monto ${itemVoucher.amount}</Card.Title>
                  <Card.Text>Disponibles: {itemVoucher.vouchers}</Card.Text>
                  <Button
                    className="btn-alternative"
                    onClick={() => navigate(`/voucher/${itemVoucher.amount}`)}
                  >
                    Generar
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}
