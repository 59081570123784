import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
// import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from '../../hooks/useForm'
import { useDispatch, useSelector, useStore } from 'react-redux'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'
import { encrypt, decryptObject, encryptObject } from '../../helpers/crypto'
// import { TimerRateLimit } from './TimeRateLimit'

export const PartnerLogin = () => {
  const [loading, setLoading] = useState(false)

  const { rateLimit } = useSelector((state) => state.auth)

  const encryptedKey = encrypt(process.env.REACT_APP_API_KEY)

  const userLogin = async (userName, password) => {
    const url = `${process.env.REACT_APP_API_URL}/client/login`
    const token = localStorage.getItem('token') || ''
    const encryptedToken = encrypt(token)

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        key: encryptedKey,
        Authorization: `Bearer ${encryptedToken}`,
      },
    }
    const requestData = {
      userName,
      password,
    }

    const encryptedData = encryptObject(requestData)
    const res = await axios.post(url, { data: encryptedData }, config)

    const result = decryptObject(res.data.data)

    localStorage.setItem('userToken', result.token)
    localStorage.setItem('userId', result.id)
    localStorage.setItem('clientId', result.clientId)

    return result
  }

  const store = useStore()

  const captchaRef = useRef()
  const btnRef = useRef()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) {
        setLoading(false)
      }
    }, 5000)

    return () => clearTimeout(timer)
  }, [loading])

  const initialForm = {
    userName: '',
    password: '',
  }

  const [values, handleInputChange, reset] = useForm(initialForm)

  const { userName, password } = values

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (userName === '' || password === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Campos vacios',
        text: 'Por favor ingrese los datos requeridos',
        showConfirmButton: false,
      })
      setLoading(false)
      return
    }
     const userData = await userLogin(userName, password)

     if (userData) navigate('/portal', { replace: true })
    reset()
    setLoading(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col xs={0} md={2}></Col>
          <Col xs={12} md={8} style={{ textAlign: 'center' }}>
            <h1 className="bold mt-5 mb-5">
              Inicia sesión a nuestro portal de partners
            </h1>
          </Col>
          <Col xs={0} md={2}></Col>
        </Row>
      </Container>
      <div className="div-bg-dark">
        <Row style={{ marginLeft: '0', marginRight: '0' }}>
          <Col xs={0} md={3} sm={2} lg={3}></Col>
          <Col
            xs={12}
            md={6}
            sm={8}
            lg={6}
            className="mt-5"
            style={{
              textAlign: 'center',
              paddingLeft: '0',
              paddingRight: '0',
              zIndex: 10,
            }}
          >
            <div className="card-form">
              <div className="label-form">
                <label htmlFor="publicKey">Usuario</label>
              </div>
              <InputMask
                type="text"
                name="userName"
                value={userName}
                onChange={handleInputChange}
                className="field-form"
              ></InputMask>
              <div className="label-form">
                <label htmlFor="code">Contraseña</label>
              </div>
              <InputMask
                type="password"
                name="password"
                value={password}
                onChange={handleInputChange}
                className="field-form"
              ></InputMask>
            </div>
          </Col>
          <Col xs={0} md={3} sm={2} lg={3}></Col>
        </Row>

        <Row style={{ marginLeft: '0', marginRight: '0' }}>
          <Col xs={0} md={3} sm={2} lg={3}></Col>
          <Col xs={12} md={6} sm={8} lg={6}>
            <p className="p-form mb-5">*Requerido</p>
          </Col>
          <Col xs={0} md={3} sm={2} lg={3}></Col>
        </Row>
      </div>

      <Container>
        <Row>
          <Col xs={0} md={4}></Col>
          <Col xs={12} md={4} style={{ textAlign: 'center' }}>
            {loading ? (
              <Spinner
                animation="border"
                variant="dark"
                className="spinner-form"
              />
            ) : rateLimit ? null : (
              <button
                className="btn-canjear"
                type="submit"
                ref={btnRef}
              >
                Ingresar
              </button>
            )}
          </Col>
          <Col xs={0} md={4}></Col>
        </Row>
        <Row>
          <Col xs={0} md={0}></Col>
          <Col xs={12} md={12} style={{ textAlign: 'center' }}>
            <h3 className="bold mt-5 mb-5 h2-soporte">
              Si tuviste un inconveniente puedes contactarnos a<br />
              soporte@ditobanx.com
            </h3>
          </Col>
          <Col xs={0} md={0}></Col>
        </Row>
      </Container>
      </form>
    </>
  )
}
