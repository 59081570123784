import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code'
import { useDispatch } from 'react-redux'
import { clearTarjetaInfo } from '../../actions/tarjetas'
import Barcode from 'react-jsbarcode'
import ReactToPrint from 'react-to-print'

export const VoucherResult = ({ userToken }) => {
  const [loading, setLoading] = useState(false)

   var componentRef = null
    
   const setComponentRef = (ref) => {
      componentRef = ref;
    }
  
    const reactToPrintContent = () => {
      return componentRef;
    }

  const vAmount = localStorage.getItem('vAmount')
  const vCode1 = localStorage.getItem('vCode1')
  const vCode2 = localStorage.getItem('vCode2')
  const vBarCode = localStorage.getItem('vBarcode')

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const finishVoucher = async (e) => {
    e.preventDefault()
    localStorage.removeItem('vAmount')
    localStorage.removeItem('vCode1')
    localStorage.removeItem('vCode2')
    localStorage.removeItem('vBarcode')

    navigate('/portal')
  }

  const printVoucher = async (e) => {
    e.preventDefault()
    localStorage.removeItem('vAmount')
    localStorage.removeItem('vCode1')
    localStorage.removeItem('vCode2')
    localStorage.removeItem('vBarcode')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(clearTarjetaInfo())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) {
        setLoading(false)
      }
    }, 5000)

    return () => clearTimeout(timer)
  }, [loading])

  if (!userToken) {
    return <Navigate to="/login" replace />
  }

  return (
    <>
      <Container>
        <Row>
          <Col xs={0} md={2}></Col>
          <Col xs={12} md={8} style={{ textAlign: 'center' }}>
            <h1 className="bold mt-5 mb-5">Voucher</h1>
          </Col>
          <Col xs={0} md={2}></Col>
        </Row>
      </Container>
      <div className="div-bg-dark">
        <Row style={{ marginLeft: '0', marginRight: '0' }}>
          <Col xs={0} md={3} sm={2} lg={3}></Col>
          <Col
            xs={12}
            md={6}
            sm={8}
            lg={6}
            className="mt-5 mb-5"
            style={{
              textAlign: 'center',
              paddingLeft: '0',
              paddingRight: '0',
              zIndex: 10,
            }}
          >
            <div className="card-form">
              <TicketElement
                ref={setComponentRef}
                monto={vAmount}
                codigo1={vCode1}
                codigo2={vCode2}
                vBarCode={vBarCode}
              />
              {/* <div className="ticket" aria-hidden="true">
                <div className="ticket__header">
                  <div className="ticket__co">
                    <img
                      src={require('../../assets/img/logoTicket.png')}
                      style={{ maxHeight: '135px' }}
                      className="d-inline-block align-top img-fluid"
                      alt="Ditobanx logo"
                    />
                    <span className="ticket__co-name">${vAmount}</span>
                    <span className="u-upper ticket__co-subname">Voucher</span>
                  </div>
                </div>
                <div className="ticket__body">
                  <p className="ticket__route">Gracias por tu compra!</p>
                  <p className="ticket__description">
                    Para realizar el canje tu wallet debe<br></br> aceptar
                    transferencias vía On-Chain o vía Lightning (LNURL)
                  </p>
                  <div className="ticket__timing">
                    <p>
                      <span className="u-upper ticket__small-label">
                        Codigo 1
                      </span>
                      <span className="ticket__detail">{vCode1}</span>
                    </p>
                    <br></br>
                    <p>
                      <span className="u-upper ticket__small-label">
                        Codigo 2
                      </span>
                      <span className="ticket__detail">{vCode2}</span>
                    </p>
                  </div>
                  <p className="ticket__description">
                    Para redimir tu voucher puedes ir a <br></br>
                    <strong>https://tarjetas.ditobanx.com/canjear</strong>
                  </p>
                  <p className="ticket__description">
                    {' '}
                    o puedes escanear el siguiente codigo QR<br></br> que te
                    llevara a el sitio:
                  </p>
                  <QRCode
                    size={100}
                    value={'https://tarjetas.ditobanx.com/canjear'}
                  />
                  <p className="u-upper ticket__admit">
                    Voucher de un solo uso
                  </p>
                  <p className="ticket__fine-print">
                    Si experimentas algun problema con el caje de tu voucher,
                    <br></br> puedes escribirnos a{' '}
                    <strong>soporte@ditobanx.com</strong>.{' '}
                  </p>
                  <p className="ticket__fine-print">
                    Voucher no pueden ser reemplazado en caso de perdidas o
                    daño.{' '}
                  </p>
                  <Barcode value={vBarCode} />
                </div>
              </div> */}
              <Row xs={2} md={2} className="mt-5">
                <form onSubmit={printVoucher}>
                  <Col style={{ textAlign: 'center' }}>
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="btn-alternative btn-realizado-canjear"
                          type="submit"
                        >
                          Imprimir
                        </button>
                      )}
                      content={reactToPrintContent}
                    />
                  </Col>
                </form>

                <form onSubmit={finishVoucher}>
                  <Col style={{ textAlign: 'center' }}>
                    <button
                      className="btn-canjear btn-realizado-finalizar"
                      type="submit"
                    >
                      Finalizar
                    </button>
                  </Col>
                </form>
              </Row>
            </div>
          </Col>
          <Col xs={0} md={3} sm={2} lg={3}></Col>
        </Row>
      </div>
      <br></br>
      <Container>
        <Row>
          <Col xs={0} md={0}></Col>
          <Col xs={12} md={12} style={{ textAlign: 'center' }}>
            <h3 className="bold mt-5 mb-5 h2-soporte">
              Si tuviste un inconveniente puedes contactarnos a<br />
              soporte@ditobanx.com
            </h3>
          </Col>
          <Col xs={0} md={0}></Col>
        </Row>
      </Container>
    </>
  )
}

export const TicketElement = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <div
        className="l-col-right ticket-wrap"
        aria-label="A fake boat ticket demonstrating mixing font weights and widths"
      >
        <div className="ticket" aria-hidden="true">
          <div className="ticket__header">
            <div className="ticket__co">
              <img
                src={require('../../assets/img/logoTicket.png')}
                style={{ maxHeight: '135px' }}
                className="d-inline-block align-top img-fluid"
                alt="Ditobanx logo"
              />
              <span className="ticket__co-name">${props.monto}</span>
              <span className="u-upper ticket__co-subname">Voucher</span>
            </div>
          </div>
          <div className="ticket__body">
            <p className="ticket__route">Gracias por tu compra!</p>
            <p className="ticket__description">
              Para realizar el canje tu wallet debe<br></br> aceptar
              transferencias vía On-Chain o vía Lightning (LNURL)
            </p>
            <div className="ticket__timing">
              <p>
                <span className="u-upper ticket__small-label">Codigo 1</span>
                <span className="ticket__detail">{props.codigo1.toLowerCase()}</span>
              </p>
              <br></br>
              <p>
                <span className="u-upper ticket__small-label">Codigo 2</span>
                <span className="ticket__detail">{props.codigo2}</span>
              </p>
            </div>
            <p className="ticket__description">
              Para redimir tu voucher puedes ir a <br></br>
              <strong>https://tarjetas.ditobanx.com/canjear</strong>
            </p>
            <p className="ticket__description">
              {' '}
              o puedes escanear el siguiente codigo QR<br></br> que te llevara a
              el sitio:
            </p>
            <QRCode
              size={100}
              value={'https://tarjetas.ditobanx.com/canjear'}
            />
            <p className="u-upper ticket__admit">Voucher de un solo uso</p>
            <p className="ticket__fine-print">
              Si experimentas algun problema con el caje de tu voucher,<br></br>{' '}
              puedes escribirnos a <strong>soporte@ditobanx.com</strong>.{' '}
            </p>
            <p className="ticket__fine-print">
              Voucher no pueden ser reemplazados en caso de perdidas o daño.{' '}
            </p>
            <Barcode value={props.vBarCode} />
          </div>
        </div>
      </div>
    </div>
  )
})
