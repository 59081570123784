import { Col, Container, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../styles/Home.css';
import tarjeta from '../assets/tarjetas.svg';
import wallet1 from '../assets/wallet1.svg';
import wallet2 from '../assets/wallet2.svg';
import wallet3 from '../assets/wallet3.svg';
import bitcoinBeach from '../assets/bitconbeach.svg';
import chivoLogo from '../assets/img/LogoChivo.svg'
import strikeLogo from '../assets/img/strikelogo.png'
import playstore from '../assets/googleplaystore-btn.svg';
import appstore from '../assets/appstore-btn.svg';
import imgOpacity from '../assets/BitcoinOpacity.png';
import { changeFoundTarjeta, clearTarjetaInfo } from '../actions/tarjetas';
import { resetCaptchaInfo } from '../actions/auth';


export const Home = () => {

    const [screen, setScreen] = useState({matches: window.matchMedia("(min-width: 768px)").matches});
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handlerScreen = e => setScreen({matches: e.matches});
    window.matchMedia("(min-width: 768px)").addEventListener('change', handlerScreen);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])

    useEffect(() => {
        dispatch(resetCaptchaInfo());
        dispatch(changeFoundTarjeta());
        dispatch(clearTarjetaInfo());
    }, [dispatch])
    

    const handleClick = () => {
        navigate('/canjear');
    }

  return (
    <Container>
      <Row style={{ marginTop: '4rem' }}>
        <Col xs={0} md={3}></Col>
        <Col xs={12} md={6} style={{ textAlign: 'center' }}>
          <h1 className="bold">
            Recibe
            <img
              src={require('../assets/DitoBanxBitcoinV3.png')}
              alt="tarjeta"
              className="img-bitcoin-title-home"
            />
          </h1>
        </Col>
        <Col xs={0} md={3}></Col>
      </Row>
      <Row>
        <Col xs={0} md={1}></Col>
        <Col xs={12} md={10} style={{ textAlign: 'center' }}>
          <h1 className="bold">al instante en tu wallet favorita</h1>
        </Col>
        <Col xs={0} md={1}></Col>
      </Row>
      <Row style={{ marginTop: '2rem' }}>
        <Col xs={0} md={3}></Col>
        <Col xs={12} md={6} style={{ textAlign: 'center' }}>
          <button className="btn-canjear" onClick={handleClick}>
            Canjea tu tarjeta
          </button>
        </Col>
        <Col xs={0} md={3}></Col>
      </Row>
      <Row style={{ marginTop: '2rem' }}>
        <Col xs={0} md={3}></Col>
        <Col xs={12} md={6} style={{ textAlign: 'center' }}>
          <img
            className="img-tarjeta-home img-fluid"
            src={tarjeta}
            alt="tarjeta"
          />
        </Col>
        <Col xs={0} md={3}></Col>
      </Row>
      <Row style={{ marginTop: '2rem' }}>
        <Col xs={0} md={0}></Col>
        <Col xs={12} md={12} style={{ textAlign: 'left' }}>
          <h1 className="text-h1-light">Pasos</h1>
        </Col>
        <Col xs={0} md={0}></Col>
      </Row>
      <Row style={{ marginTop: '2rem' }}>
        <Col xs={0} md={0}></Col>
        <Col
          xs={12}
          md={12}
          style={{ textAlign: 'left' }}
          className="centrar-items"
        >
          <div className="div-cuadro-numero">1</div>
          <h5 className="text-h1-light">
            Raspa las barras que contiene tu código secreto.
          </h5>
        </Col>
        <Col xs={0} md={0}></Col>
      </Row>
      <Row>
        <Col xs={0} md={0}></Col>
        <Col
          xs={12}
          md={12}
          style={{ textAlign: 'left' }}
          className="centrar-items mt-3"
        >
          <div className="div-cuadro-numero">2</div>
          <h5 className="text-h1-light">
            Ingresa los códigos secretos en los espacios indicados.
          </h5>
        </Col>
        <Col xs={0} md={0}></Col>
      </Row>
      <Row>
        <Col xs={0} md={0}></Col>
        <Col
          xs={12}
          md={12}
          style={{ textAlign: 'left' }}
          className="centrar-items mt-3"
        >
          <div className="div-cuadro-numero">3</div>
          <h5 className="text-h1-light">
            Coloca la dirección de tu wallet y recibe tus bitcoin.{' '}
          </h5>
        </Col>
        <Col xs={0} md={0}></Col>
      </Row>
      <Row>
        <Col xs={0} md={0}></Col>
        <Col
          xs={12}
          md={12}
          style={{ textAlign: 'left' }}
          className="centrar-items mt-3"
        >
          <div className="div-rectangulo-naranja">
            <ul>
              {/* <li style={{listStyle: 'none'}}>*Aplica cargo de costo de red del 0.00002 btc.</li> */}
              <li style={{ listStyle: 'none' }}>
                *5% de comisión por desembolso.
              </li>
            </ul>
          </div>
        </Col>
        <Col xs={0} md={0}></Col>
      </Row>

      {screen.matches ? (
        <>
          <Row>
            <Col xs={0} md={0}></Col>
            <Col xs={12} md={12} style={{ textAlign: 'right' }}>
              <img
                className="img-bitcoinsign-home"
                src={require('../assets/DitoBanxBitcoinSign.png')}
                alt="tarjeta"
              />
            </Col>
            <Col xs={0} md={0}></Col>
          </Row>
        </>
      ) : null}

      <Row>
        <Col xs={12} md={12}>
          <div>
            <img className="img-opacity-home" src={imgOpacity} alt="tarjeta" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={0} md={0} sm={0} lg={2}></Col>
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={8}
          style={{ textAlign: 'center', marginTop: '6rem' }}
        >
          <h4 className="h4-home" style={{ color: 'black' }}>
            Si no tienes una wallet para recibir tus bitcoin te sugerimos que
            utilices las siguientes opciones
          </h4>
        </Col>
        <Col xs={0} md={0} sm={0} lg={2}></Col>
      </Row>

      <div className="d-none d-md-block d-lg-block">
        <Row>
          <Col xs={0} md={3} sm={3} lg={3}></Col>
          <Col xs={6} md={2} sm={2} lg={2} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={strikeLogo}
                    alt="satoshi"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=zapsolutions.strike'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={
                        'https://apps.apple.com/us/app/strike-bitcoin-payments/id1488724463'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={appstore}
                        alt="appstore"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={6} md={2} sm={2} lg={2} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={bitcoinBeach}
                    alt="satoshi"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=com.galoyapp'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                    <img
                        className="img-fluid img-stores"
                        src={appstore}
                        alt="appstore"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={6} md={2} sm={2} lg={2} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={chivoLogo}
                    alt="satoshi"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=com.chivo.wallet'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={
                        'https://apps.apple.com/us/app/chivo-wallet/id1581515981'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={appstore}
                        alt="appstore"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={3} sm={3} lg={3}></Col>
        </Row>

        <Row>
          <Col xs={0} md={3} sm={3} lg={3}></Col>
          <Col xs={6} md={2} sm={2} lg={2} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={wallet2}
                    alt="satoshi"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=com.livingroomofsatoshi.wallet'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={
                        'https://apps.apple.com/us/app/wallet-of-satoshi/id1438599608'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={appstore}
                        alt="appstore"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={6} md={2} sm={2} lg={2} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={wallet3}
                    alt="blue"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=io.bluewallet.bluewallet'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={
                        'https://apps.apple.com/app/bluewallet-bitcoin-wallet/id1376878040'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={appstore}
                        alt="appstore"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={2} sm={2} lg={2} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={wallet1}
                    alt="muun"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=io.muun.apollo'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={
                        'https://apps.apple.com/us/app/muun-wallet/id1482037683'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={appstore}
                        alt="appstore"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={3} sm={3} lg={3}></Col>
        </Row>
      </div>

      <div className="d-block d-md-none d-lg-none d-xl-none">
        <Row>
          <Col xs={6} sm={6} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={strikeLogo}
                    alt="satoshi"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=zapsolutions.strike'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={
                        'https://apps.apple.com/us/app/strike-bitcoin-payments/id1488724463'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={appstore}
                        alt="appstore"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={6} sm={6} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={bitcoinBeach}
                    alt="satoshi"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=com.galoyapp'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                  <img
                      className="img-fluid img-stores"
                      src={appstore}
                      alt="appstore"
                  />
                </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={6} sm={6} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={chivoLogo}
                    alt="satoshi"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=com.chivo.wallet'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={
                        'https://apps.apple.com/us/app/chivo-wallet/id1581515981'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={appstore}
                        alt="appstore"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={6} sm={6} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={wallet2}
                    alt="satoshi"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=com.livingroomofsatoshi.wallet'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={
                        'https://apps.apple.com/us/app/wallet-of-satoshi/id1438599608'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={appstore}
                        alt="appstore"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={6} sm={6} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={wallet3}
                    alt="blue"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=io.bluewallet.bluewallet'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={
                        'https://apps.apple.com/app/bluewallet-bitcoin-wallet/id1376878040'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={appstore}
                        alt="appstore"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={6} sm={6} style={{ textAlign: 'center' }}>
            <Row className="mb-5">
              <Col>
                <div className="mb-3">
                  <img
                    className="img-wallet-home img-fluid"
                    src={wallet1}
                    alt="muun"
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=io.muun.apollo'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={playstore}
                        alt="playstore"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={
                        'https://apps.apple.com/us/app/muun-wallet/id1482037683'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid img-stores"
                        src={appstore}
                        alt="appstore"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Row style={{ marginTop: '4rem', marginBottom: '4rem' }}>
        <Col xs={0} md={4}></Col>
        <Col xs={12} md={4} style={{ textAlign: 'center' }}>
          <button className="btn-canjear" onClick={handleClick}>
            Canjea tu tarjeta
          </button>
        </Col>
        <Col xs={0} md={4}></Col>
      </Row>
    </Container>
  )
}
