import { types } from '../types/types';
import { fetchConToken, postFetch } from '../helpers/fetch';
import Swal from 'sweetalert2';
import { decryptObject } from '../helpers/crypto';
import { rateLimitCheck } from './auth';

export const addTarjetaInfo = (tarjeta) => {
    return (dispatch) => {
       dispatch(getTarjetaValues(tarjeta));
    }
}

export const getVoucherValue = (value) => {
  return async (dispatch) => {
    dispatch(getVoucher(value))
  }
}

export const startGetTarjetaValues = (publicKey, code) => {
  return async (dispatch) => {
    const resp = await postFetch(
      process.env.REACT_APP_ENPOINT_GET_GIFTCARD,
      { publicKey, code }
    );
    try {
      
      const body = await resp;

      if (!body.data && body.key === 'card:notFound') {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Códigos Inválidos',
          iconColor: '#F79327',
          text: 'Por favor, ingrese una combinación de códigos válida.',
          showConfirmButton: false,
          timer: 4500,
        })
        return;
      }
      
      body.data = decryptObject(body.data)
      const {publicKey, code, amount, qrCode} = body.data;
      if (body.status) {

        if (body.data.canProceed && body.data.available) {


          dispatch(getTarjeta({
            publicKey,
            code, 
            amount,
            qrCode
          }));
          

        } else if (body.data.available === false) {

          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'La tarjeta ya fue canjeada',
            iconColor: '#F79327',
            text: 'Por favor intenta con otra tarjeta',
            showConfirmButton: false,
            timer: 4500
          })
          
        } else if (!body.data.canProceed){

          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Espere un momento',
            iconColor: '#F79327',
            text: 'Para realizar otro intento por favor espere',
            showConfirmButton: false,
            timer: 4500
          })

        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error',
            iconColor: '#F79327',
            text: 'Por favor intenta de nuevo',
            showConfirmButton: false,
            timer: 4500
          })
        }

      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          iconColor: '#F79327',
          title: 'Error',
          text: 'El código no es válido',
          showConfirmButton: false,
          timer: 4500
        })

      }
    } catch (error) {
      if (resp.key === undefined) {
        // Swal.fire({
        //   position: 'center',
        //   icon: 'warning',
        //   iconColor: '#F79327',
        //   title: 'Error',
        //   text: 'El código no es válido hhhhhhh',
        //   showConfirmButton: false,
        //   timer: 4500
        // })
        dispatch(rateLimitCheck());
      }

    }
  };
};

export const redeemGiftcard = (code, publicKey, wallet, browser, ip, email, amount, fee) => {
  return async (dispatch) => {
    try {
      const data = { publicKey, code, wallet, browser, ip, email, amount, fee };
      const resp = await fetchConToken(
        process.env.REACT_APP_ENPOINT_REDEEM_GIFTCARD,
        data,
        'POST'
      );
      const body = await resp
      if (body.status && body.data !== null) {
        dispatch(redeemTarjeta(decryptObject(body.data)));
      } else if (body.key === 'onchain:refused') {

        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Costo de red elevado',
          iconColor: '#F79327',
          text: 'En este momento, el valor de la comisión para que se procese esta transacción en la blockchain, supera el valor de tu giftcard a recibir, Por favor intenta más tarde.',
          showConfirmButton: true,
          confirmButtonColor: '#F79327',
          confirmButtonText: 'Aceptar',
        })

      }
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Error de conexión',
        iconColor: '#F79327',
        text: 'Por favor intente más tarde',
        showConfirmButton: false,
        timer: 5500
      })
    }
  };
}

export const strikeRedeem = (
  code,
  publicKey,
  wallet,
  browser,
  ip,
  email,
  amount,
  fee
) => {
  return async (dispatch) => {
    try {
      const data = { publicKey, code, wallet, browser, ip, email, amount, fee }
      const resp = await fetchConToken(
        process.env.REACT_APP_ENPOINT_STRIKE_REDEEM,
        data,
        'POST'
      )
      const body = await resp
      if (body.status && body.data !== null) {
        dispatch(redeemTarjeta(decryptObject(body.data)))
      } else if (body.key === 'onchain:refused') {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Costo de red elevado',
          iconColor: '#F79327',
          text: 'En este momento, el valor de la comisión para que se procese esta transacción en la blockchain, supera el valor de tu giftcard a recibir, Por favor intenta más tarde.',
          showConfirmButton: true,
          confirmButtonColor: '#F79327',
          confirmButtonText: 'Aceptar',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Error de conexión',
        iconColor: '#F79327',
        text: 'Por favor intente más tarde',
        showConfirmButton: false,
        timer: 5500,
      })
    }
  }
}

export const changeFoundTarjeta = () => {
  return (dispatch) => {
    dispatch(foundTarjeta());
  }
}
export const clearTarjetaInfo = () => {
  return (dispatch) => {
    dispatch(clearTarjeta());
  }
}

export const foundTarjeta = () => ({
  type: types.tarjetaFoundTarjeta
})

export const foundTarjetaTrue = () => ({
  type: types.tarjetaFoundTarjetaTrue
})

export const getTarjeta = (tarjeta) => ({
  type: types.tarjetaGetTarjeta,
  payload: tarjeta
});

export const getVoucher = (voucher) => ({
  type: types.voucherGetVoucher,
  payload: voucher,
})

export const redeemTarjeta = (tarjeta) => ({
  type: types.tarjetaRedeemTarjeta,
  payload: tarjeta
});

export const getTarjetaValues = (tarjeta) => ({
  type: types.tarjetaValuesTarjeta,
  payload: tarjeta
});

const clearTarjeta = () => ({
  type: types.tarjetaClearTarjeta
})
