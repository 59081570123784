import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { changeFoundTarjeta } from '../actions/tarjetas';
import { Home } from '../components/Home';
import { Canjear } from '../components/Canjear';
import { ConfirmarTarjeta } from '../components/ConfirmarTarjeta';
import  Footer  from '../components/Footer';
import { Header } from '../components/Header';
import { LoadingScreen } from '../components/LoadingScreen';
import { Realizado } from '../components/Realizado';
import { WalletSelector } from '../components/WalletSelector';
import { CommonConfirm } from '../components/redeems/othersConfirm';
import { StrikeConfirm } from '../components/redeems/strikeConfirm';
import { StrikeReceipt } from '../components/confirmations/strikeConfirmation'
import { PartnerLogin } from '../components/partners/partnerLogin';
import { Timer } from '../components/Timer';
import { PartnerPortal } from '../components/partners/partnerPortal';
import { Voucher } from '../components/partners/voucher';
import { VoucherResult } from '../components/partners/voucherResult';
import { Giftcard } from '../components/partners/giftcardActivation';
import { useParams } from 'react-router-dom'


export const DashboardRoutes = () => {
  
  const { captchaToken, checking } = useSelector(state => state.auth);
  const sessionUserToken = localStorage.getItem('userToken')
  const dispatch = useDispatch();
  const { amount } = useParams()

  const location = useLocation();
  const bodypdRef = useRef();

  useEffect(() => {
    const bodypd = bodypdRef.current;
    if (checking) {
      bodypd.classList.remove('body-bg');
    } else {
      if (
        location.pathname === '/canjear' ||
        location.pathname === '/confirmar' ||
        location.pathname === '/realizado' ||
        location.pathname === '/selector' ||
        location.pathname === '/common' ||
        location.pathname === '/strike' ||
        location.pathname === '/comprobante/strike' ||
        location.pathname === '/login' ||
        location.pathname === '/portal' ||
        location.pathname === '/voucher/40' ||
        location.pathname === '/voucher-result' ||
        location.pathname === '/giftcard'
      ) {
        bodypd.classList.remove('body-bg')
      } else {
        bodypd.classList.add('body-bg')
      }
    }
  }, [location, checking]);

  useEffect(() => {
    if (location.pathname !== '/canjear' && location.pathname !== '/confirmar') {
      dispatch(changeFoundTarjeta());
    }
  }, [location, dispatch])
  
  

  return (
    <div className="body-bg" ref={bodypdRef}>
      {checking ? (
        <LoadingScreen />
      ) : (
        <>
          <Header />

          <Routes>
            <Route index path="home" element={<Home />} />
            <Route path="canjear" element={<Canjear />} />
            <Route path="login" element={<PartnerLogin />} />
            <Route
              path="portal"
              element={<PartnerPortal userToken={sessionUserToken} />}
            />
            <Route
              path="voucher/:amount"
              element={<Voucher userToken={sessionUserToken} />}
            />
            <Route
              path="voucher-result"
              element={<VoucherResult userToken={sessionUserToken} />}
            />
            <Route
              path="giftcard/:amount"
              element={<Giftcard userToken={sessionUserToken} />}
            />
            <Route
              path="selector"
              element={<WalletSelector captcha={captchaToken} />}
            />
            <Route
              path="common"
              element={<CommonConfirm captcha={captchaToken} />}
            />
            <Route
              path="strike"
              element={<StrikeConfirm captcha={captchaToken} />}
            />
            <Route
              path="confirmar"
              element={<ConfirmarTarjeta captcha={captchaToken} />}
            />
            <Route
              path="comprobante/strike"
              element={<StrikeReceipt captcha={captchaToken} />}
            />
            <Route
              path="realizado"
              element={<Realizado captcha={captchaToken} />}
            />

            <Route path="/*" element={<Home />} />
          </Routes>

          <Timer />

          <Footer />
        </>
      )}
    </div>
  )
}
