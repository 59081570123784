import React, { useEffect } from 'react'
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom'
import { isTokenStillValid, parseJWT } from '../helpers/validateToken'
import Swal from 'sweetalert2'
import '../styles/Selector.css'
import logoMuun from '../assets/wallet1.svg'
import logoSatoshi from '../assets/wallet2.svg'
import logoBlue from '../assets/wallet3.svg'
import logoStrike from '../assets/img/strikelogo.png'
import logoBeach from '../assets/bitconbeach.svg'
import logoChivo from '../assets/img/ChivoLogo.svg'

export const WalletSelector = ({ captcha }) => {
  const navigate = useNavigate()
  let token = localStorage.getItem('token')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  if (!captcha) {
    return <Navigate to="/canjear" replace />
  }

  const handleCommonAction = async (e) => {
    e.preventDefault()
    if (token && isTokenStillValid(parseJWT(token))) {
      navigate('/confirmar')
    } else if (!token) {
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: 'Por favor recargue la pagina.',
        icon: 'warning',
        iconColor: '#F79327',
        showCancelButton: false,
        confirmButtonColor: '#F79327',
        confirmButtonText: 'Recargar la pagina',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      })
    } else if (!isTokenStillValid(parseJWT(token))) {
      Swal.fire({
        title: 'Tiempo de sesión expirado',
        text: 'Por favor recargue la pagina.',
        icon: 'warning',
        iconColor: '#F79327',
        showCancelButton: false,
        confirmButtonColor: '#F79327',
        confirmButtonText: 'Recargar la pagina',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      })
    }
  }

  const handleStrikeAction = async (e) => {
    e.preventDefault()
    if (token && isTokenStillValid(parseJWT(token))) {
      navigate('/strike')
    } else if (!token) {
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: 'Por favor recargue la pagina.',
        icon: 'warning',
        iconColor: '#F79327',
        showCancelButton: false,
        confirmButtonColor: '#F79327',
        confirmButtonText: 'Recargar la pagina',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      })
    } else if (!isTokenStillValid(parseJWT(token))) {
      Swal.fire({
        title: 'Tiempo de sesión expirado',
        text: 'Por favor recargue la pagina.',
        icon: 'warning',
        iconColor: '#F79327',
        showCancelButton: false,
        confirmButtonColor: '#F79327',
        confirmButtonText: 'Recargar la pagina',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      })
    }
  }

  let buttons = document.querySelectorAll('.selected')

  buttons.forEach((button) => {
    button.addEventListener('click', (_) => {
      buttons.forEach((button) => {
        button.classList.remove('edit')
      })
      button.classList.toggle('edit')
    })
  })

  return (
    <div className="bg-white">
      <Container>
        <Row>
          <Col xs={0} md={2}></Col>
          <Col xs={12} md={8} style={{ textAlign: 'center' }}>
            <h1 className="bold mt-5 mb-5">
              Selecciona la billetera donde quieres recibir tus
              <img
                src={require('../assets/DitoBanxBitcoinV3.png')}
                alt="btc"
                className="img-bitcoin-title-home"
              />
            </h1>
          </Col>
          <Col xs={0} md={2}></Col>
        </Row>
      </Container>
      <div className="div-bg-light">
        <Container>
          <br className="d-none d-md-block d-lg-block" />
          <br className="d-block d-md-none d-lg-none d-xl-none" />
          <h4
            className="bold d-block d-md-none d-lg-none d-xl-none text-center"
            style={{ fontSize: '19px' }}
          >
            Si tu billetera está en la siguiente lista o utilizas cualquier otra
            que acepte transferencias vía On-Chain o vía Lightning (LNURL) haz
            clic en el botón de continuar.
          </h4>
          <Row className="d-none d-md-block d-lg-block">
            <Col xs={12} md={9} lg={9} style={{ textAlign: 'left' }}>
              <h4 className="bold mt-5 mb-5" style={{ fontSize: '19px' }}>
                Si tu billetera está en la siguiente lista o utilizas cualquier
                otra que acepte transferencias vía On-Chain o vía Lightning
                (LNURL) haz clic en el botón de continuar.
              </h4>
            </Col>
            <Col xs={0} md={3} lg={3}></Col>
          </Row>
          <div className="mb-5 d-none d-md-block d-lg-block">
            <Row style={{ textAlign: 'left' }}>
              <Col xs={0} md={7} lg={7}>
                <Row>
                  <Col xs={0} md={4} lg={4} className="text-center">
                    <button
                      onClick={handleCommonAction}
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                    >
                      <img
                        className="img-fluid img-selector-wallets"
                        src={logoBeach}
                        alt="tarjeta"
                      />
                    </button>

                    <p
                      style={{
                        fontWeight: '500',
                        marginTop: '9px',
                        fontSize: '14px',
                      }}
                    >
                      Bitcoin Beach Wallet
                    </p>
                  </Col>
                  <Col xs={0} md={4} lg={4} className="text-center">
                    <button
                      onClick={handleCommonAction}
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                    >
                      <img
                        className="img-fluid img-selector-wallets"
                        src={logoChivo}
                        alt="tarjeta"
                      />
                    </button>

                    <p
                      style={{
                        fontWeight: '500',
                        marginTop: '9px',
                        fontSize: '14px',
                      }}
                    >
                      Chivo Wallet
                    </p>
                  </Col>
                  <Col xs={0} md={4} lg={4} className="text-center">
                    <button
                      onClick={handleCommonAction}
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                    >
                      <img
                        className="img-fluid img-selector-wallets"
                        src={logoSatoshi}
                        alt="tarjeta"
                      />
                    </button>

                    <p
                      style={{
                        fontWeight: '500',
                        marginTop: '9px',
                        fontSize: '14px',
                      }}
                    >
                      Wallet of Satoshi
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={0} md={4} lg={4} className="text-center">
                    <button
                      onClick={handleCommonAction}
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                    >
                      <img
                        className="img-fluid img-selector-wallets"
                        src={logoMuun}
                        alt="tarjeta"
                      />
                    </button>

                    <p
                      style={{
                        fontWeight: '500',
                        marginTop: '9px',
                        fontSize: '14px',
                      }}
                    >
                      Muun Wallet
                    </p>
                  </Col>
                  <Col xs={0} md={4} lg={4} className="text-center">
                    <button
                      onClick={handleCommonAction}
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                    >
                      <img
                        className="img-fluid img-selector-wallets"
                        src={logoBlue}
                        alt="tarjeta"
                      />
                    </button>

                    <p
                      style={{
                        fontWeight: '500',
                        marginTop: '9px',
                        fontSize: '14px',
                      }}
                    >
                      Blue Wallet
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={0} md={2} lg={2}></Col>
              <Col xs={0} md={3} lg={3}>
                <button
                  style={{ width: '10rem' }}
                  className="btn-canjear"
                  onClick={handleCommonAction}
                >
                  Continuar
                </button>
              </Col>
            </Row>
          </div>

          <div className="d-block d-md-none d-lg-none d-xl-none">
            <Row style={{ padding: '2rem' }}>
              <Col xs={6} sm={6} className="text-center">
                <button
                  onClick={handleCommonAction}
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                >
                  <img
                    className="img-fluid img-selector-wallets"
                    src={logoBeach}
                    alt="tarjeta"
                  />
                </button>

                <p
                  style={{
                    fontWeight: '500',
                    marginTop: '9px',
                    fontSize: '14px',
                  }}
                >
                  Bitcoin Beach Wallet
                </p>
              </Col>
              <Col xs={6} sm={6} className="text-center">
                <button
                  onClick={handleCommonAction}
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                >
                  <img
                    className="img-fluid img-selector-wallets"
                    src={logoChivo}
                    alt="tarjeta"
                  />
                </button>

                <p
                  style={{
                    fontWeight: '500',
                    marginTop: '9px',
                    fontSize: '14px',
                  }}
                >
                  Chivo Wallet
                </p>
              </Col>
              <Col xs={6} sm={6} className="text-center">
                <button
                  onClick={handleCommonAction}
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                >
                  <img
                    className="img-fluid img-selector-wallets"
                    src={logoSatoshi}
                    alt="tarjeta"
                  />
                </button>

                <p
                  style={{
                    fontWeight: '500',
                    marginTop: '9px',
                    fontSize: '14px',
                  }}
                >
                  Wallet of Satoshi
                </p>
              </Col>
              <Col xs={6} sm={6} className="text-center">
                <button
                  onClick={handleCommonAction}
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                >
                  <img
                    className="img-fluid img-selector-wallets"
                    src={logoMuun}
                    alt="tarjeta"
                  />
                </button>

                <p
                  style={{
                    fontWeight: '500',
                    marginTop: '9px',
                    fontSize: '14px',
                  }}
                >
                  Muun Wallet
                </p>
              </Col>
              <Col xs={6} sm={6} className="text-center">
                <button
                  onClick={handleCommonAction}
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                >
                  <img
                    className="img-fluid img-selector-wallets"
                    src={logoBlue}
                    alt="tarjeta"
                  />
                </button>

                <p
                  style={{
                    fontWeight: '500',
                    marginTop: '9px',
                    fontSize: '14px',
                  }}
                >
                  Blue Wallet
                </p>
              </Col>
            </Row>
          </div>
          <br className="d-block d-md-none d-lg-none d-xl-none" />
          <div className="position-relative">
            <div className="btnCentered">
              <button
                style={{ width: '10rem' }}
                className="btn-canjear d-block d-md-none d-lg-none d-xl-none"
                onClick={handleCommonAction}
              >
                Continuar
              </button>
            </div>
            <br className="d-block d-md-none d-lg-none d-xl-none" />
          </div>
          <br className="d-block d-md-none d-lg-none d-xl-none" />
        </Container>
        <br></br>
      </div>
      <div>
        <Container className="mb-5">
          <h4
            className="bold mt-5 mb-5 d-block d-md-none d-lg-none d-xl-none text-center"
            style={{ fontSize: '19px' }}
          >
            Si utilizas billetera Strike haz click en el botón de continuar
          </h4>
          <Row className="d-none d-md-block d-lg-block">
            <Col xs={12} md={9} lg={9} style={{ textAlign: 'left' }}>
              <h4 className="bold mt-5 mb-5" style={{ fontSize: '19px' }}>
                Si utilizas billetera Strike haz click en el botón de continuar
              </h4>
            </Col>
            <Col xs={0} md={3} lg={3}></Col>
          </Row>
          <Row style={{ textAlign: 'left' }}>
            <Col xs={0} md={7} lg={7}>
              <Row>
                <Col xs={0} md={4} lg={4} className="text-center">
                  <button
                    onClick={handleStrikeAction}
                    style={{ border: 'none', backgroundColor: 'transparent' }}
                  >
                    <img
                      className="img-fluid img-selector-strike"
                      src={logoStrike}
                      alt="tarjeta"
                    />
                  </button>

                  <p
                    style={{
                      fontWeight: '500',
                      marginTop: '9px',
                      fontSize: '14px',
                    }}
                  >
                    Strike
                  </p>
                </Col>
                <Col xs={0} md={4} lg={4}></Col>
                <Col xs={0} md={4} lg={4}></Col>
              </Row>
            </Col>
            <Col xs={0} md={2} lg={2}></Col>
            <Col xs={0} md={3} lg={3}>
              <button 
                style={{ width: '10rem' }}
                className="btn-canjear d-none d-md-block d-lg-block"
                onClick={handleStrikeAction}
              >
                Continuar
              </button>
            </Col>
          </Row>
          <br className="d-block d-md-none d-lg-none d-xl-none" />
          <div className="position-relative d-md-none d-lg-none d-xl-none">
            <div className="btnCentered d-md-none d-lg-none d-xl-none">
              <button 
                style={{ width: '10rem' }}
                className="btn-canjear d-block d-md-none d-lg-none d-xl-none"
                onClick={handleStrikeAction}
              >
                Continuar
              </button>
            </div>
            <br className="d-block d-md-none d-lg-none d-xl-none" />
          </div>
        </Container>
      </div>
    </div>
  )
}
