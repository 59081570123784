import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Spinner, Form } from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom'
// import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from '../../hooks/useForm'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { clearTarjetaInfo, startGetTarjetaValues } from '../../actions/tarjetas'
import { isTokenStillValid, parseJWT } from '../../helpers/validateToken'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'
import { useParams } from 'react-router-dom'
import { encrypt, decryptObject, encryptObject } from '../../helpers/crypto'
import axios from 'axios'
// import { TimerRateLimit } from './TimeRateLimit'

export const Voucher = ({ userToken }) => {
  const [loading, setLoading] = useState(false)

  const { rateLimit } = useSelector((state) => state.auth)

  const store = useStore()
  const { amount } = useParams()

  const captchaRef = useRef()
  const btnRef = useRef()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(clearTarjetaInfo())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) {
        setLoading(false)
      }
    }, 5000)

    return () => clearTimeout(timer)
  }, [loading])

  const initialForm = {
    code: '',
  }

  const [values, handleInputChange, reset] = useForm(initialForm)

  const { code } = values

  if (!userToken) {
    return <Navigate to="/login" replace />
  }
  let token = localStorage.getItem('token')

  const encryptedKey = encrypt(process.env.REACT_APP_API_KEY)

  const requestVoucher = async (code) => {
    const url = `${process.env.REACT_APP_API_URL}/client/voucher/request`
    const token = localStorage.getItem('token') || ''
    const encryptedToken = encrypt(token)

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        key: encryptedKey,
        Authorization: `Bearer ${encryptedToken}`,
      },
    }
    const requestData = {
      code,
      clientID: localStorage.getItem('clientId'),
      userID: localStorage.getItem('userId'),
      amountVar: amount
    }

    const encryptedData = encryptObject(requestData)
    const res = await axios.post(url, { data: encryptedData }, config)

    const result = decryptObject(res.data.data)

    return result
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (code === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Campos vacios',
        text: 'Por favor ingrese los datos requeridos',
        showConfirmButton: false,
      })
      setLoading(false)
      return
    }

    const voucherData = await requestVoucher(code)
    console.log('voucher: ', voucherData)

    localStorage.setItem('vAmount', voucherData.amount)
    localStorage.setItem('vCode1', voucherData.publicKey)
    localStorage.setItem('vCode2', voucherData.code)
    localStorage.setItem('vBarcode', voucherData.barCode)

    if (voucherData) navigate('/voucher-result')

    reset()
    setLoading(false)
  }

  return (
    <>
      <div className="bg-light">
        <form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={8} style={{ textAlign: 'center' }}>
                <h1 className="bold mt-5 mb-5">Generar Voucher</h1>
              </Col>
              <Col xs={0} md={2}></Col>
            </Row>
          </Container>
          <div className="div-bg-dark">
            <Row style={{ marginLeft: '0', marginRight: '0' }}>
              <Col xs={0} md={3} sm={2} lg={3}></Col>
              <Col
                xs={12}
                md={6}
                sm={8}
                lg={6}
                className="mt-5"
                style={{
                  textAlign: 'center',
                  paddingLeft: '0',
                  paddingRight: '0',
                  zIndex: 10,
                }}
              >
                <div className="card-form">
                  <Row>
                    <Col xs={0} md={2}></Col>
                    <Col xs={12} md={8} style={{ textAlign: 'center' }}>
                      <h3 className="bold mt-5 mb-5">Valor de ${amount}</h3>
                    </Col>
                    <Col xs={0} md={2}></Col>
                  </Row>
                  <div className="label-form">
                    <label htmlFor="code">*Ingresa tu PIN de seguridad</label>
                  </div>
                  <InputMask
                    type="text"
                    name="code"
                    value={code}
                    onChange={handleInputChange}
                    className="field-form"
                  ></InputMask>
                </div>
              </Col>
              <Col xs={0} md={3} sm={2} lg={3}></Col>
            </Row>

            <Row style={{ marginLeft: '0', marginRight: '0' }}>
              <Col xs={0} md={3} sm={2} lg={3}></Col>
              <Col xs={12} md={6} sm={8} lg={6}>
                <p className="p-form mb-5">*Requerido</p>
              </Col>
              <Col xs={0} md={3} sm={2} lg={3}></Col>
            </Row>
          </div>

          <Container>
            <Row>
              <Col xs={0} md={4}></Col>
              <Col xs={12} md={4} style={{ textAlign: 'center' }}>
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="dark"
                    className="spinner-form"
                  />
                ) : rateLimit ? null : (
                  <button className="btn-canjear" type="submit" ref={btnRef}>
                    Generar
                  </button>
                )}
              </Col>
              <Col xs={0} md={4}></Col>
            </Row>
            <Row>
              <Col xs={0} md={0}></Col>
              <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                <h3 className="bold mt-5 mb-5 h2-soporte">
                  Si tuviste un inconveniente puedes contactarnos a<br />
                  soporte@ditobanx.com
                </h3>
              </Col>
              <Col xs={0} md={0}></Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  )
}
