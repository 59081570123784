import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, UNSAFE_NavigationContext, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { changeFoundTarjeta, clearTarjetaInfo } from '../actions/tarjetas';
import checkedIcon from '../assets/checkedIcon.svg'
import { decryptObject, encrypt } from '../helpers/crypto';
import { useForm } from '../hooks/useForm';

export const Realizado = ({ captcha }) => {

  const [showEmail, setShowEmail] = useState("OK");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  //eslint-disable-next-line
  const {
    priceBTC,
    equivalentBTC,
    commission,
    totalBTC,
    amount,
    transactionId,
    wallet,
    networkComision,
  } = useSelector((state) => state.tarjetas)
  const encryptedKey = encrypt(process.env.REACT_APP_API_KEY);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const useBackListener = (callback) => {
    const navigator = useContext(UNSAFE_NavigationContext).navigator;
  
    useEffect(() => {
      const listener = ({ location, action }) => {
        if (action === "POP") {
          callback({ location, action });
        }
      };
  
      const unlisten = navigator.listen(listener);
      return unlisten;
    }, [callback, navigator]);
  };

  let separator = '/';
  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  const initialForm = {
    email: null,
    email2: null,
    email3: null,
  }

  const [values, handleInputChange, reset] = useForm(initialForm);

  const {email, email2, email3} = values;

  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
  

  const sendEmail = async (email, email2, email3, transactionId) => {
    const url = process.env.REACT_APP_API_URL + '/giftcard/receip/send';
    const token = localStorage.getItem('token') || '';
    const encryptedToken = encrypt(token);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        key: encryptedKey,
        Authorization: `Bearer ${encryptedToken}`,
      },
    };
    const requestData = {
      email,
      email2,
      email3,
      transactionId,
      type: 'btc',
    }
    const res = await axios.post(url, requestData, config)
    res.data.data = decryptObject(res.data.data)
    if (res.data.status && res.data.key === 'receip:sent') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Email enviado',
        iconColor: '#F7931A',
        text: 'Te hemos enviado un email con los datos de la transacción',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#F7931A',
      })
      setShowEmail("NO");
    }
  }

  const submitEmail = (e) => {
    e.preventDefault();
    if (!validEmail.test(email)) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Email invalido',
        iconColor: '#F79327',
        text: 'Por favor ingresa un email valido',
        showConfirmButton: false,
        timer: 4500
      })
      return;
    }
    sendEmail(email, email2, email3, transactionId);
    reset();
  }

  useBackListener(({ location }) => {
    dispatch(changeFoundTarjeta());
    dispatch(clearTarjetaInfo());
    navigate("/", { replace: true });
  });

  const handlerOtherGiftCard = () => {
    dispatch(changeFoundTarjeta());
    dispatch(clearTarjetaInfo());
    navigate('/canjear');
  }

  const goHome = () => {
    dispatch(changeFoundTarjeta());
    dispatch(clearTarjetaInfo());
    navigate('/home');
  }

  if (!captcha) {
    return <Navigate to="/canjear" replace />;
  }

  return (
    <Container>
      <Row className="mt-5 mb-5">
        <Col xs={0} sm={0} md={1} lg={2}></Col>
        <Col xs={12} sm={12} md={10} lg={8} style={{ textAlign: 'center' }}>
          <div className="card-transaction card-shadow">
            <Container>
              <Row>
                <Col>
                  <img
                    className="img-card-transaction img-fluid"
                    src={checkedIcon}
                    alt="checked"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1 className="bold mb-3">Transacción realizada</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 className="mb-3">
                    En unos minutos podrás ver el monto reflejado en tu wallet
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    Valor de la tarjeta:
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right' }}
                  >
                    ${amount % 1 === 0 ? amount + '.00' : amount}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    Equivalente en BTC
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right' }}
                  >
                    {Number.parseFloat(equivalentBTC).toFixed(8)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    Comisión de servicio BTC
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right' }}
                  >
                    5% ({Number.parseFloat(commission).toFixed(8)})
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row>
                <Col>
                  <p className="bold mt-3" style={{ textAlign: 'left' }}>
                    Costo de red:
                  </p>
                </Col>
                <Col>
                  <p className="bold mt-3" style={{ textAlign: 'right' }}>
                    {Number.parseFloat(networkComision).toFixed(8)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row style={{ backgroundColor: '#ffffff' }}>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    BTC a recibir:
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right', color: '#00953F' }}
                  >
                    {Number.parseFloat(totalBTC).toFixed(8)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    Fecha:
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right' }}
                  >
                    {' '}
                    {`${year}${separator}${
                      month < 10 ? `0${month}` : `${month}`
                    }${separator}${date}`}
                  </p>
                </Col>
                {/* <Col><p className='bold mt-3' style={{textAlign: 'right'}}>25/03/2022 01:47:48 pm</p></Col> */}
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    Wallet:
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right' }}
                  >
                    {wallet}
                  </p>
                </Col>
                {/* <Col><p className='bold mt-3' style={{textAlign: 'right'}}>25/03/2022 01:47:48 pm</p></Col> */}
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row>
                <Col>
                  <p className="bold mt-3" style={{ textAlign: 'center' }}>
                    Identificador de transacción:
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                  <div>
                    <p className="bold">
                      <a
                        href={`https://mempool.space/tx/${transactionId}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ fontSize: '14px' }}
                      >
                        {transactionId.substring(0, 12)}...
                        {transactionId.substring(transactionId.length - 12)}
                      </a>
                    </p>
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <h5
                    style={{ textAlign: 'center' }}
                    className="bold mt-3 me-5 ms-5"
                  >
                    Dirección de Wallet
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={0} md={0}></Col>
                <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                  <p className="p-wallet">{wallet}</p>
                </Col>
                <Col xs={0} md={0}></Col>
              </Row> */}
              {showEmail === 'OK' ? (
                <>
                  <Row>
                    <Col className="linea-separadora-dark"></Col>
                  </Row>
                  <Row>
                    <Col xs={0} md={0}></Col>
                    <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                      <h5 className="bold mt-3 mb-3 h5-email-realizado">
                        Ingresa tu email para recibir el detalle de la
                        transacción (Opcional)
                      </h5>
                    </Col>
                    <Col xs={0} md={0}></Col>
                  </Row>

                  <div className="d-block d-md-none d-lg-none d-xl-none">
                    <Row>
                      <Col xs={8} sm={8}>
                        <input
                          type="email"
                          name="email"
                          value={email || ''}
                          onChange={handleInputChange}
                          className="field-email"
                          placeholder="email 1"
                        />
                      </Col>
                      <Col xs={4} sm={4} style={{ textAlign: 'center' }}>
                        <button
                          className="btn-email-send"
                          onClick={submitEmail}
                        >
                          Enviar
                        </button>
                      </Col>
                    </Row>
                  </div>

                  <div className="d-none d-md-block d-lg-block">
                    <Row>
                      <Col xs={0} md={0}></Col>
                      <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                        <input
                          type="email"
                          name="email"
                          value={email || ''}
                          onChange={handleInputChange}
                          className="field-email mb-2"
                          placeholder="email 1"
                        />
                      </Col>
                      <Col xs={0} md={0}></Col>
                    </Row>
                    <Row>
                      <Col xs={0} md={0}></Col>
                      <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                        <button
                          style={{ width: '8rem' }}
                          className="btn-canjear"
                          onClick={submitEmail}
                        >
                          Enviar
                        </button>
                      </Col>
                      <Col xs={0} md={0}></Col>
                    </Row>
                  </div>
                </>
              ) : null}
            </Container>
          </div>
        </Col>
        <Col xs={0} sm={0} md={1} lg={2}></Col>
      </Row>

      <div className="d-none d-md-block d-lg-block">
        <Row>
          <Col xs={0} md={2}></Col>
          <Col xs={6} md={4} style={{ textAlign: 'center' }}>
            <button
              className="btn-alternative btn-realizado-canjear"
              onClick={handlerOtherGiftCard}
            >
              Canjear otra
            </button>
          </Col>
          <Col xs={6} md={4} style={{ textAlign: 'center' }}>
            <button
              className="btn-canjear btn-realizado-finalizar"
              onClick={goHome}
            >
              Finalizar
            </button>
          </Col>
          <Col xs={0} md={2}></Col>
        </Row>
      </div>

      <div className="d-block d-md-none d-lg-none d-xl-none">
        <Row>
          <Col xs={6} sm={6} style={{ textAlign: 'center' }}>
            <button className="btn-alternative" onClick={handlerOtherGiftCard}>
              Canjear otra
            </button>
          </Col>
          <Col xs={6} sm={6} style={{ textAlign: 'center' }}>
            <button className="btn-canjear" onClick={goHome}>
              Finalizar
            </button>
          </Col>
        </Row>
      </div>

      <Row>
        <Col xs={0} sm={0} md={1} lg={2}></Col>
        <Col xs={12} sm={12} md={10} lg={8}>
          <div className="acordion-card">
            <h4 className="bold mt-3 mb-3" style={{ textAlign: 'center' }}>
              ¿Necesitas ayuda?
            </h4>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  No puedo canjear el código de mi tarjeta de regalo
                </Accordion.Header>
                <Accordion.Body>
                  <p style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                    Si durante el proceso de canje de tu tarjeta presentas algún
                    problema, asegúrate de estar conectado a internet y que
                    poseas navegación (puedes realizar la prueba abriendo una
                    pestaña adicional y cargando alguna página que no sea&nbsp;
                    <a
                      href="https://google.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      google.com
                    </a>
                    ).
                  </p>
                  <p style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                    Si el problema aún persiste y para que podamos brindarte un
                    mejor servicio, contáctanos a nuestro correo&nbsp;
                    <a href="mailto:soporte@ditobanx.com">
                      soporte@ditobanx.com
                    </a>
                    &nbsp;y compártenos la siguiente información:
                  </p>
                  <ol>
                    <li style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                      Capturas de pantalla (de todo el navegador donde se
                      visualice la barra URL) del mensaje de error y códigos que
                      está utilizando.
                    </li>
                    <li style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                      Captura de ambos lados de la giftcard que está utilizando,
                      donde los códigos traseros sean completamente visibles.
                    </li>
                    <li style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                      Descripción de las condiciones en las cuales ocurre el
                      error para que nuestro equipo pueda replicarlo.
                    </li>
                    <li style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                      Información de a donde podamos contactárte: email y número
                      de teléfono, en caso de ser necesario el contacto por este
                      medio.
                    </li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  ¿Cómo puedo redimir mi giftcard?
                </Accordion.Header>
                <Accordion.Body>
                  <p style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                    En la parte trasera encontrarás dos códigos rascables que
                    estarán identificados y que podrás ingresar en el apartado
                    que nuestro sitio web te indique. Deberás seleccionar el
                    método que deseas utilizar para recibir tu bitcoin, ya sea
                    vía lightning u onchain (asegúrate de que tu billetera sea
                    compatible con estos métodos).
                  </p>
                  <p style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                    Al momento de realizar el canje, aparecerá una ventana
                    confirmando la transacción que te dará la opción de enviar
                    tu comprobante al correo que desees.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Me mostró la ventana de confirmación y aun no veo reflejado en
                  mi wallet el monto de la giftcard
                </Accordion.Header>
                <Accordion.Body>
                  <p style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                    No te preocupes, es posible que la blockchain tarde unos
                    minutos en validar y realizar la transacción. Si esta fue
                    vía onchain, da clic en el número de referencia (el cual
                    podrás encontrar en el comprobante que se muestra en el
                    navegador o en el correo electrónico al cual lo enviaste, en
                    caso de que hayas ingresado uno y dado clic en el botón
                    enviar), que te redireccionará a{' '}
                    <a
                      href="https://mempool.space/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://mempool.space/
                    </a>{' '}
                    con la referencia de tu transacción, y podrás ver en tiempo
                    real el bloque en el que se encuentra y cuándo se procesará.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>
        <Col xs={0} sm={0} md={1} lg={2}></Col>
      </Row>

      <Row>
        <Col xs={0} md={1}></Col>
        <Col xs={12} md={10} style={{ textAlign: 'center' }}>
          <h4 className="bold mt-3 mb-5 h2-soporte">
            Si tuviste un inconveniente puedes contactarnos a<br />
            soporte@ditobanx.com
          </h4>
        </Col>
        <Col xs={0} md={1}></Col>
      </Row>
    </Container>
  )
}
