import Swal from 'sweetalert2';
import { fetchConToken, fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';
import { decryptObject } from '../helpers/crypto';


export const startLogin = (ip, city, country) => {
    return async(dispatch) => {
        try {
            const data = { ip, city, country }
    
            const resp = await fetchSinToken(process.env.REACT_APP_ENPOINT_CREATE_USER, data, 'POST');

            const body = await resp.json();
            body.data = decryptObject(body.data)

            if (body.status) {
                localStorage.setItem('token', body.data.token);
               
                dispatch(login({
                    ip: await body.data.ip,
                    city: await body.data.city,
                    country: await body.data.country,
                }))
            } else { 
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Error',
                    iconColor: '#F79327',
                    text: body.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        } catch (error) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Error de conexión',
                iconColor: '#F79327',
                text: 'Por favor intente más tarde',
                showConfirmButton: false,
                timer: 5500
            })
            dispatch(checkingFinish());
            dispatch(rateLimitCheck());
        }
    }
}

export const resetCaptchaInfo = () => {
    return (dispatch) => {
        dispatch(resetCaptcha());
    }
}

export const checkingCaptchaFinish = () => {
    return async (dispatch) => {
        dispatch(checkingFinish());
    }
}

export const rateLimitCheck = () => {
    return async (dispatch) => {
        dispatch(rateLimit());
    }
}

export const rateLimitCheckRestart = () => {
    return (dispatch) => {
        dispatch(rateLimitRestart());
    }
}

export const checkingStart = () => {
    return async (dispatch) => {
        dispatch(checking());
    }
}

const rateLimitRestart = () => ({
    type: types.authRateLimitRestart
})

const rateLimit = () => ({
    type: types.authRateLimit
})

const checking = () => ({
    type: types.authChecking
})

const checkingFinish = () => ({
    type: types.authCheckingFinish
});

const login = (captcha) =>({
    type: types.authLogin,
    payload: captcha
});

const resetCaptcha = () => ({
    type: types.authRecaptchaReset
})

export const startValidateCaptcha = (captchaToken) => {

    return async (dispatch) => {
        try {
       
            const resp = await fetchConToken(process.env.REACT_APP_ENPOINT_VALIDATE_RECAPTCHA, {captchaToken}, 'POST');

            const body = await resp;

            body.data = decryptObject(body.data)

            if (body.status && body.data.success) {
                dispatch(checkingCaptcha(captchaToken));
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    iconColor: '#F79327',
                    title: 'Error',
                    text: body.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        } catch (error) {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                iconColor: '#F79327',
                title: 'Intenta mas tarde',
                text: 'El captcha ha expirado',
                showConfirmButton: false,
                timer: 4500
            })
            dispatch(rateLimitCheck());
        }
    }
}

const checkingCaptcha = (captchaToken) => ({
    type: types.authRecaptcha,
    payload: captchaToken
});
