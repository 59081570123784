import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Spinner, Form } from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom'
// import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from '../../hooks/useForm'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { clearTarjetaInfo, startGetTarjetaValues } from '../../actions/tarjetas'
import { isTokenStillValid, parseJWT } from '../../helpers/validateToken'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'
// import { TimerRateLimit } from './TimeRateLimit'
import { postFetch } from '../../helpers/fetch'
import { encrypt, decryptObject, encryptObject } from '../../helpers/crypto'
import axios from 'axios'
import { useParams } from 'react-router-dom'

export const Giftcard = ({ userToken }) => {
  const [loading, setLoading] = useState(false)

  const { rateLimit } = useSelector((state) => state.auth)

  const store = useStore()

  const captchaRef = useRef()
  const btnRef = useRef()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [giftcard, setGiftCard] = useState(null)

  const encryptedKey = encrypt(process.env.REACT_APP_API_KEY)
  const { amount } = useParams()

  const requestGiftcard = async (code, barCode) => {
    const url = `${process.env.REACT_APP_API_URL}/client/giftcard/activate`
    const token = localStorage.getItem('token') || ''
    const encryptedToken = encrypt(token)

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        key: encryptedKey,
        Authorization: `Bearer ${encryptedToken}`,
      },
    }
    const requestData = {
      barCode: giftcard ? giftcard : barCode,
      code,
      clientID: localStorage.getItem('clientId'),
      userID: localStorage.getItem('userId'),
      amount,
    }

    const encryptedData = encryptObject(requestData)
    const res = await axios.post(url, { data: encryptedData }, config)

    const result = decryptObject(res.data.data)

    return result
  }
  

  const getCardByScanner = () => {
    let barcode = ''
    let interval

    document.addEventListener('keydown', (evt) => {
      if (interval) clearInterval(interval)

      if (evt.code === 'Enter') {
        if (barcode) handleBarCode(barcode)
        barcode = ''
        return
      }

      if (evt.key !== 'Shift') barcode += evt.key
      interval = setInterval(() => (barcode = ''), 20)
    })
  }

  const handleBarCode = async (barcode) => {
    setGiftCard(barcode)
  }

  useEffect(() => {
    getCardByScanner()
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(clearTarjetaInfo())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) {
        setLoading(false)
      }
    }, 5000)

    return () => clearTimeout(timer)
  }, [loading])

  const initialForm = {
    code: '',
    barCode: '',
  }

  const [values, handleInputChange, reset] = useForm(initialForm)

  const { code, barCode } = values

  let token = localStorage.getItem('token')

  if (!userToken) {
    return <Navigate to="/login" replace />
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (code === '' || barCode === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Campos vacios',
        text: 'Por favor ingrese los datos requeridos',
        showConfirmButton: false,
      })
      setLoading(false)
      return
    }

    const giftcardData = await requestGiftcard(code, barCode)

    if (giftcardData) {
      Swal.fire({
        icon: 'success',
        title: 'Giftcard Activada',
        text: `Giftcard con código de barra: ${barCode}, activada exitosamente.`,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#F7931A',
      }).then(() => {
        navigate('/portal')
      })
    }

    reset()
    setLoading(false)
  }

  const formatChars = {
    '*': '[A-Za-z0-9]',
    // '-' : '[-]' // Para que no sea necesario que el usuario escriba el guion hay que comentarlo
  }

  return (
    <>
      <div className="bg-light">
        <form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={8} style={{ textAlign: 'center' }}>
                <h1 className="bold mt-5 mb-5">Activar Giftcard</h1>
              </Col>
              <Col xs={0} md={2}></Col>
            </Row>
          </Container>
          <div className="div-bg-dark">
            <Row style={{ marginLeft: '0', marginRight: '0' }}>
              <Col xs={0} md={3} sm={2} lg={3}></Col>
              <Col
                xs={12}
                md={6}
                sm={8}
                lg={6}
                className="mt-5"
                style={{
                  textAlign: 'center',
                  paddingLeft: '0',
                  paddingRight: '0',
                  zIndex: 10,
                }}
              >
                <div className="card-form">
                  <div className="label-form">
                    <label htmlFor="code">
                      *Digita el código o escanea el código de barra
                    </label>
                  </div>
                  <InputMask
                    formatChars={formatChars}
                    mask="******-******"
                    maskChar={''}
                    type="text"
                    name="barCode"
                    onChange={handleInputChange}
                    value={giftcard ? giftcard : barCode}
                    className="field-form"
                    pattern="[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}"
                    alwaysShowMask={false}
                  ></InputMask>
                  <div className="label-form">
                    <label htmlFor="code">*Ingresa tu PIN de seguridad</label>
                  </div>
                  <InputMask
                    type="text"
                    name="code"
                    value={code}
                    onChange={handleInputChange}
                    className="field-form"
                  ></InputMask>
                </div>
              </Col>
              <Col xs={0} md={3} sm={2} lg={3}></Col>
            </Row>

            <Row style={{ marginLeft: '0', marginRight: '0' }}>
              <Col xs={0} md={3} sm={2} lg={3}></Col>
              <Col xs={12} md={6} sm={8} lg={6}>
                <p className="p-form mb-5">*Requerido</p>
              </Col>
              <Col xs={0} md={3} sm={2} lg={3}></Col>
            </Row>
          </div>

          <Container>
            <Row>
              <Col xs={0} md={4}></Col>
              <Col xs={12} md={4} style={{ textAlign: 'center' }}>
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="dark"
                    className="spinner-form"
                  />
                ) : rateLimit ? null : (
                  <button className="btn-canjear" type="submit" ref={btnRef}>
                    Activar
                  </button>
                )}
              </Col>
              <Col xs={0} md={4}></Col>
            </Row>
            <Row>
              <Col xs={0} md={0}></Col>
              <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                <h3 className="bold mt-5 mb-5 h2-soporte">
                  Si tuviste un inconveniente puedes contactarnos a<br />
                  soporte@ditobanx.com
                </h3>
              </Col>
              <Col xs={0} md={0}></Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  )
}
