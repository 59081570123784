import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { startLogin } from '../actions/auth';
import { DashboardRoutes } from './DashboardRoutes';

export const AppRouter = () => {

  const dispatch = useDispatch();

  const getData = async () => {
    // const res = await axios.get('https://geolocation-db.com/json/')
    dispatch(startLogin('0.0.0.0', 'San Salvador', 'El Salvador'))
  }
  
  useEffect( () => {
    
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <BrowserRouter>
        <Routes>
          <Route path="/*" element={<DashboardRoutes />} />
        </Routes>
    </BrowserRouter> 
  )
}
